:root {
  --primary-50: #f2fffa;
  --primary-100: #c8ffeb;
  --primary-200: #81ffd2;
  --primary-300: #22f9ab;
  --primary-400: #00da8b;
  --primary-500: #00b473;
  --primary-600: #009b63;
  --primary-700: #008555;
  --primary-800: #00633f;
  --primary-900: #00452c;

  --bluegrey-50:#f7f9f9;
  --bluegrey-100:#d9e0e3;
  --bluegrey-200:#bbc7cd;
  --bluegrey-300:#9caeb7;
  --bluegrey-400:#7e96a1;
  --bluegrey-500:#607d8b;
  --bluegrey-600:#526a76;
  --bluegrey-700:#435861;
  --bluegrey-800:#35454c;
  --bluegrey-900:#263238;

  --grey-100:#64748B;
  --grey-110:#f8f9fa;
  --grey-200:#E9ECEF;
  --grey-300:#dee2e6;
  --grey-400:#CED4DA;

  --grey-700:#6c757d;
  --grey-800:#495057;
  --grey-900:#343A40;

  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --surface-910: #060606;

  --red-900: #9E0D0D;
  --red-500: #DC1919;
  --red-400: #FF6A6A;
  --red-100: #FFE7E6;

  --maskbg: rgba(0, 0, 0, 0.4);

  --base-01: #FFFFFF;
  --base-02: #F8F9FA;
  --base-03: #CED4DA;
  --base-04: #6C757D;

  --text-primary: #343A40;
  --text-secondary: #6C757D;
  --text-primary-inverse: #FFFFFF;
  --text-selected-item-primary: #1C2958;
  --text-invalid: #DC1919;

  --brand-primary: #008850;
  --brand-primary-light-10: rgba(0, 136, 80, 0.04);
  --brand-primary-light-20: rgba(0, 136, 80, 0.1);
  --brand-primary-light-40: #F2FFEC;
  --brand-primary-dark-40: #006B3F;
  --brand-primary-dark-50: #005633;
  --brand-primary-bright: #48D667;

  --brand-danger: #EF4444;

  --divider-tabs: #DEE2E6;
  --border-rest-input: #CED4DA;

  --bg-secondary: #F8F9FA;
  --bg-success: #E0FFD5;
  --bg-error: #EF4444; // old stuff rename to danger
  --bg-error-light-10: rgba(226, 76, 76, 0.04);
  --bg-orange: #FFBB0B;

  --bg-hover-item: #E9ECEF;
  --bg-selected-item: #F2FFEC;

  --transition-duration: .6ms;
  --topbar-height: 59px;

  --sidebar-min-width: 66px;
  --sidebar-max-width: 280px;

  --topbar-logo-height: 42px;
  --user-panel-min-width: 400px;
  --user-panel-max-width: 600px;

  --messages-error: #ffe7e6;
  --messages-warn: #FEF0CD;

  --font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  background-color: #f5f5f5;
}

html {
  font-size: 16px;
}
